import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import config from "config/vue/configurator";

// importing modules
import auth from "./auth"
import locale from "./locale"
import monitor from "./monitor"
import order from "./order"
import product from "./product"
import user from "./user"

Vue.use(Vuex);
Vue.config.devtools = config['app']['devtools'];

const vuexLocalStorage = new VuexPersist({
    storage: window.localStorage,
});

export default new Vuex.Store({

    plugins: [
        vuexLocalStorage.plugin
    ],

    modules: {
        auth,
        locale,
        monitor,
        order,
        product,
        user
    }

});
