import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {

        order: null,
        orders: null,
        error: {}
    },

    actions : {

        /**
         * Action to fetch ALL orders
         *
         * @param dispatch
         * @param data
         *
         * @returns {Promise<*>}
         */
        async fetchCurrentOrder({ commit }, data) {
            
            let response = await axios
                
                .get('/get/order/' + data.id, {
                    
                    headers: {
                        Authorization: "Bearer " + data.token
                    },
                    
                })
                
                .then(function (response) {
                    
                    commit('saveCurrentOrder', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {
                    
                    commit("saveCurrentOrder", null)
                    
                    if (error.response) {
                        
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        commit(ERROR_MESSAGE, error.response)
                        
                    } else if (error.request) {
                        
                        // The request was made but no response was received
                        console.log(error.request);
                        
                        commit(ERROR_MESSAGE, error.request)
                        
                    } else {
                        
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        
                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },
        
        /**
         * Action to fetch ALL orders
         *
         * @param dispatch
         * @param token
         *
         * @returns {Promise<*>}
         */
        async fetchOrders({ commit }, token) {
            
            let response = await axios
                
                .get('/get/orders', {
                    
                    headers: {
                        Authorization: "Bearer " + token
                    }
                    
                })
                
                .then(function (response) {
                    
                    commit('saveOrders', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {
                    
                    commit("saveOrders", null)
                    
                    if (error.response) {
                        
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        commit(ERROR_MESSAGE, error.response)
                        
                    } else if (error.request) {
                        
                        // The request was made but no response was received
                        console.log(error.request);
                        
                        commit(ERROR_MESSAGE, error.request)
                        
                    } else {
                        
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        
                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

        /**
         * Action to CREATE a new order
         *
         * @param dispatch
         * @param token
         *
         * @returns {Promise<*>}
         */
        async createOrder({ commit }, token) {
            
            let response = await axios
                
                .post('/create/order', {
                    
                    headers: {
                        Authorization: "Bearer " + token
                    },
                    
                })
                
                .then(function (response) {
                    
                    commit('saveCurrentOrder', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {
                    
                    commit("saveCurrentOrder", null)
                    
                    if (error.response) {
                        
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        commit(ERROR_MESSAGE, error.response)
                        
                    } else if (error.request) {
                        
                        // The request was made but no response was received
                        console.log(error.request);
                        
                        commit(ERROR_MESSAGE, error.request)
                        
                    } else {
                        
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        
                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },
        
        /**
         * Action to DELETE an order
         *
         * @param dispatch
         * @param data
         *
         * @returns {Promise<*>}
         */
        async deleteOrder({ commit, state }, data) {
            
            let response = await axios
                
                .delete('/delete/order/' + data.id, {
                    
                    headers: {
                        Authorization: "Bearer " + data.token
                    },
                    
                })
                
                .then(function (response) {
                    
                    commit("saveCurrentOrder", null)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {
                    
                    if (error.response) {
                        
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        commit(ERROR_MESSAGE, error.response)
                        
                    } else if (error.request) {
                        
                        // The request was made but no response was received
                        console.log(error.request);
                        
                        commit(ERROR_MESSAGE, error.request)
                        
                    } else {
                        
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        
                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

    },

    getters : {
        
        getCurrentOrder(state) {
            
            return state.order
        },
        
        getOrders(state) {

            return state.orders
        },
        
        error(state) {
            
            return state.error
        }

    },

    mutations : {
        
        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        },
        
        saveCurrentOrder(state, payload) {
            
            state.order = payload
        },
        
        saveOrders(state, payload) {

            state.orders = payload
        }

    }
}
