/* eslint-disable */

/**
 * Importing vue
 */
import Vue from 'vue';

/**
 * Importing assets
 */
import moment from 'moment'
import numeral from 'numeral'

/**
 * Register global components here and use them as a plugin in main Vue instance
 */

// Filter that capitalizes the first letter
Vue.filter('capitalize', function (value) {

    if (!value) return ''

    value = value.toString()

    return value.charAt(0).toUpperCase() + value.slice(1)
})

// Filter that formats datestamps into desired format
Vue.filter('date', function (date) {

    if (date) {

        return moment(String(date)).format('DD/MM-YYYY')
    }
})

// Filter that formats numbers into desired format
Vue.filter('number', function (number) {

    if (number) {

        return Intl.NumberFormat('da-DK', { style: "currency", currency: "DKK" }).format(number)
    }
})

// Filter that formats percentages into desired format
Vue.filter('percentage', function (number) {

    if (number) {

        return numeral(number / 100).format('0%', '')
    }
})