<template id="WorkCard">

    <div>

        <div class="row align-items-center">
            <div class="col-10 offset-1">
                <h6 class="mb-0 text-center font-weight-bolder ms-3 mb-3">
                    tune trafikskole, Industrihegnet 9, 4030 Tune, +45 70 60 30 15, info@tune-trafikskole.dk
                </h6>
            </div>
            <div class="col-1 text-end">
                <div class="dropstart">
                    <a class="text-secondary btn no-shadow" id="dropdownMarketingCard" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-lg-start px-2 py-3" aria-labelledby="dropdownMarketingCard">
                        <li>
                            <a class="dropdown-item border-radius-md">
                                ...
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <button class="dropdown-item border-radius-md text-danger" @click="showAlert">
                                Fjern
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- fields : ORDER NUMBER -->
        <input id="orderNumber" name="orderNumber" type="text" :value="'Arbejdskort sagsnr.: ' + order.id" class="form-control text-center text-2xl font-weight-bolder" readonly aria-label="orderNumber">

        <!-- fields : CUSTOMER + COURSE INFORMATION -->
        <div class="row mt-3">
            <!-- field : CUSTOMER INFORMATION -->
            <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4">
                <div class="h-100">
                    <div class="card-header no-padding">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6 class="mb-0 pb-2">
                                    Kunde Information
                                </h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <a href="javascript:">
                                    <i class="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <template v-if="order.customer">
                        <div class="form-control card-body p-3 mh-205">
                            <ul class="list-group">
                                <!-- field : CUSTOMER NAME -->
                                <li class="list-group-item border-0 ps-0 pt-0 text-sm">
                                    <template v-if="order.customer.middle_names">
                                        <strong class="text-dark" >Full Name:</strong> &nbsp; {{ order.customer.first_name + " " + order.customer.middle_names + " " + order.customer.last_name }}
                                    </template>
                                    <template v-else>
                                        <strong class="text-dark">Full Name:</strong> &nbsp; {{ order.customer.first_name + " " + order.customer.last_name }}
                                    </template>
                                </li>
                                <!-- field : CUSTOMER ADDRESS -->
                                <li class="list-group-item border-0 ps-0 text-sm">
                                    <template v-for="address in order.customer.addresses" v-if="address.pivot.address_type === 'home'">
                                        <strong class="text-dark">Address:</strong> &nbsp; {{ address.address1 }}
                                    </template>
                                </li>
                                <!-- field : CUSTOMER EMAIL -->
                                <li class="list-group-item border-0 ps-0 text-sm">
                                    <strong class="text-dark">Email:</strong> &nbsp; {{ order.customer.email }}
                                </li>
                                <!-- field : CUSTOMER MOBILE PHONE -->
                                <li class="list-group-item border-0 ps-0 text-sm">
                                    <template v-if="order.customer.phone.tel_mobile">
                                        <strong class="text-dark">Mobile:</strong> &nbsp; {{ order.customer.phone.tel_mobile }}
                                    </template>
                                    <template v-else>
                                        <strong class="text-dark">Mobile:</strong> &nbsp; n/a
                                    </template>
                                </li>
                                <!-- field : CUSTOMER SOCIAL ACCOUNT(S) -->
                                <li class="list-group-item border-0 ps-0 pb-0">
                                    <strong class="text-dark text-sm">Social:</strong> &nbsp;
                                    <!--                                {% for social in order.getRelation('customer').getRelation('sso') %}-->
                                    <!--                                    <a class="btn btn-{{ social.service }} btn-simple mb-0 ps-1 pe-2 py-0" href="javascript:" target="_blank">-->
                                    <!--                                        <i class="fab fa-{{ social.service }} fa-lg"></i>-->
                                    <!--                                    </a>-->
                                    <!--                                {% endfor %}-->
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form-control card-body d-flex flex-column justify-content-center text-center mh-205">
                            <a href="javascript:">
                                <i class="fa fa-plus text-secondary text-sm mb-1" aria-hidden="true"></i>
                                <h6 class="text-secondary"> Tilføj </h6>
                            </a>
                        </div>
                    </template>
                </div>
            </div>
            <!-- field : COURSE INFORMATION -->
            <div class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4">
                <div class="h-200">
                    <div class="card-header no-padding">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6 class="mb-0 pb-2">
                                    Information om forløb
                                </h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <a href="javascript:;">
                                    <i class="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-control card-body p-2 mh-205">
                        <ul class="list-group padding-sides">
                            <!-- field : ORDER CREATION DATE -->
                            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-1 border-radius-lg">
                                <div class="d-flex align-items-center">
                                    <div class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                        <svg width="12px" height="12px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mt-1">
                                            <title>spaceship</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g transform="translate(4.000000, 301.000000)">
                                                            <path d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                            <path d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                            <path d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                            <path d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" opacity="0.598539807"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h6 class="mb-1 text-dark text-sm">
                                            Sagsdato:
                                        </h6>
                                        <span class="text-xs">Oprettet d. <span class="font-weight-bold">{{ order.created_at }}</span></span>
                                    </div>
                                </div>
                            </li>
                            <!-- field : ?? -->
                            <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-1 border-radius-lg">
                                <div class="d-flex align-items-center">
                                    <div class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                        <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <title>office</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g id="office" transform="translate(153.000000, 2.000000)">
                                                            <path class="color-background" d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z" opacity="0.6"></path>
                                                            <path class="color-background" d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h6 class="mb-1 text-dark text-sm">
                                            Afdeling:
                                        </h6>
                                        <template v-if="order.department">
                                            <span class="text-xs">{{ order.department.uid }}, <span class="font-weight-bold">{{ order.department.address.zip +" "+ order.department.address.city }}</span></span>
                                        </template>
                                        <template v-else>
                                            <span class="text-xs">Afdeling <span class="font-weight-bold">EJ SAT ENDNU</span></span>
                                        </template>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button class="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i class="ni ni-bold-right" aria-hidden="true"></i></button>
                                </div>
                            </li>
                            <!-- field : CUSTOMER "KØREPRØVEBOOKING FORLØBSNR" -->
                            <li class="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                                <div class="d-flex align-items-center">
                                    <div class="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                        <svg width="12px" height="12px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="mt-1">
                                            <title>box-3d-50</title>
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g transform="translate(-2319.000000, -291.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                    <g transform="translate(1716.000000, 291.000000)">
                                                        <g transform="translate(603.000000, 0.000000)">
                                                            <path d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"></path>
                                                            <path d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z" opacity="0.7"></path>
                                                            <path d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z" opacity="0.7"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <h6 class="mb-1 text-dark text-sm">
                                            Køreprøvebooking:
                                        </h6>
                                        <span class="text-xs">Forløbsnr. <span class="font-weight-bold">Ukendt</span></span>

<!--                                        <template v-if="order.course.køreprøvebooking_id">-->
<!--                                            <span class="text-xs">Forløbsnr. <span class="font-weight-bold">{{ order.course.køreprøvebooking_id }}</span></span>-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <span class="text-xs">Forløbsnr. <span class="font-weight-bold">Ukendt</span></span>-->
<!--                                        </template>-->

                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button class="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i class="ni ni-bold-right" aria-hidden="true"></i></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- fields : INTERNAL NOTE -->
        <div class="row mt-3">
            <div class="col-12 col-md-12 col-xl-12 mt-md-0 mt-4">
                <div class="h-200">
                    <div class="card-header no-padding">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6 class="mb-0 pb-2">
                                    Interne Bemærkninger
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body no-padding">

                        <!-- https://vuejsfeed.com/blog/intergrate-quill-editor-in-vue-js-2-projects -->
                        <quill-editor ref="orderNoteInternalTextEditor" v-model="content" :config="editorOption"/>

                    </div>
                </div>
            </div>
        </div>

        <!-- field : ORDER PRODUCT LINES -->
        <div class="row mt-3">
            <div class="col-12 col-md-12 col-xl-12 mt-md-0 mt-4">
                <div class="h-200">
                    <div class="card-header no-padding">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6 class="mb-0 pb-2">
                                    Ordrerlinjer
                                </h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <a href="javascript:">
                                    <i class="fas fa-plus text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-control card-body no-padding">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Produkt
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">
                                            Antal
                                        </th>
                                        <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1">
                                            Enhed
                                        </th>
                                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Rabat
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Stk. pris
                                        </th>
                                        <th class="text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Pris
                                        </th>
                                    </tr>
                                </thead>
<!--                                <tbody>-->
                                <!-- https://dev.to/0shuvo0/creating-a-sortable-list-using-vuejs-3e2f -->
                                <draggable v-model="order.products" tag="tbody">
                                    <tr v-for="product in order.products">
                                        <!-- product -->
                                        <td>
                                            <div class="d-flex px-3 py-1">
                                                <div>
                                                    <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg" class="avatar me-3" alt="image">
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">
<!--                                                            {% for meta in product.getRelation('meta') %}-->
<!--                                                            {% if meta.i18n == session.lang %}-->
<!--                                                            {{ meta.title }}-->
<!--                                                            {% endif %}-->
<!--                                                            {% endfor %}-->
                                                        produkt titel
                                                    </h6>
                                                    <p class="text-sm font-weight-bold text-secondary mb-0">
                                                        sku: <span class="text-success">{{ product.sku }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- quantity -->
                                        <td>
                                            <p class="text-sm text-end font-weight-bold mb-0">
                                                {{ product.pivot.quantity }}
                                            </p>
                                        </td>
                                        <!-- unit -->
                                        <td class="align-middle text-start text-sm">
                                            <p class="text-sm font-weight-bold mb-0">
<!--                                                    {{ product.getRelation('unit').title }}-->
                                                stk.
                                            </p>
                                        </td>
                                        <!-- discount -->
                                        <td class="align-middle text-center">
                                            {{ product.pivot.discount_percentage|percentage }}
                                        </td>
                                        <!-- price pr. unit -->
                                        <td class="text-end">
                                            <p class="text-sm text-end font-weight-bold mb-0">
                                                {{ product.sales_price|number }}
                                            </p>
                                        </td>
                                        <!-- price -->
                                        <td class="text-end">
                                            <p class="text-sm text-end font-weight-bold mb-0">
<!--                                                    {% if product.pivot.discount_percentage %}-->
<!--                                                    {{ ((product.sales_price - (product.sales_price / 100 * product.pivot.discount_percentage)) * product.pivot.quantity)|number_format(2, ',', '.') }}-->
<!--                                                    {% else %}-->
<!--                                                    {{ (product.sales_price * product.pivot.quantity)|number_format(2, ',', '.') }}-->
<!--                                                    {% endif %}-->
                                                123000
                                            </p>
                                        </td>
                                    </tr>
                                </draggable>
<!--                                </tbody>-->
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>

    // importing Vuex maps
    import { mapActions, mapGetters } from "vuex";

    export default {

        name: "WorkCard",
        components: {

            // ...

        },
        created() {

            // ...her ville nok være et godt sted at finde current order
            this.getCurrentOrder({token: this.token, id: this.$router.currentRoute.params.id})

                .then(() => {

                    console.log("Current order state populated")

                })

                .catch(() => {

                    console.log("Deleting the order failed")

                })

        },
        mounted() {

            // ...

        },
        data() {
            return {

                // ...
                editorOption: {

                    placeholder: 'Text i dette felt vil IKKE fremgå af fakturaen!',
                }

            }
        },
        computed: {

            ...mapGetters({
                token: 'auth/authenticated',
                order: 'order/getCurrentOrder'
            })

        },
        methods: {

            ...mapActions({

                getCurrentOrder: 'order/fetchCurrentOrder',
                deleteOrder: 'order/deleteOrder',
            }),

            showAlert() {

                const swalWithBootstrapButtons = this.$swal.mixin({

                    customClass: {
                        confirmButton: 'btn bg-gradient-success',
                        cancelButton: 'btn bg-gradient-danger'
                    },
                    buttonsStyling: false

                })

                swalWithBootstrapButtons.fire({

                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    // type: type,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true

                }).then((result) => {

                    if (result.value) {

                        this.deleteOrder({token: this.token, id: this.$router.currentRoute.params.id})

                            .then(() => {

                                swalWithBootstrapButtons.fire(

                                    'Deleted!',
                                    'Order is removed from catalog.',
                                    'success'

                                ).then((result) => {

                                    window.location.href = '/sales/order/overview';

                                })

                            })

                            .catch(() => {

                                console.log("Deleting the order failed")

                            })

                    } else if (

                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel

                    ) {

                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            'No product was deleted',
                            'error'
                        )
                    }
                })
            }

        }


    }

</script>

<style scoped lang="scss">

    // ...

</style>
