<template>
    <div id="overview">

<!--        <OrdersComponent/>-->

    </div>
</template>

<script>

    // importing vuex maps
    import { mapActions, mapGetters } from "vuex"

    // (~) is an alias for ./src/components
    //import OrdersComponent from "~/back/main/dashboards/sales/OrdersComponent";

    export default {
        name: 'overview',
        components: {

            //OrdersComponent,

        },
        created() {

            // ...

        },
        mounted() {

            // ...

        },
        data() {
            return {

                // ...

            }
        },
        computed: {

            ...mapGetters({
                // user: 'auth/user',
                // locales: 'locale/locales'
            })

        },
        methods: {

            ...mapActions({

                // ...

            })

        }
    }

</script>

<style lang="scss" scoped>

    // ...

</style>
