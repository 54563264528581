<template>

    <h4>vue component</h4>

</template>

<script>

    export default {

        name: "FullUserDetails"

    }

</script>

<style scoped lang="scss">

    // ...

</style>
