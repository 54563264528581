import Vue from 'vue';
import App from '^/App';
import GlobalComponents from "~/globalComponents";
import router from '#/www/vue-router';
import store from '&/store';
import axios from "axios";
import config from "config/vue/configurator";
import VueSweetalert2 from 'vue-sweetalert2';
import regeneratorRuntime from "regenerator-runtime";

require("&/subscriber");
require("./globalFilters");

Vue.config.productionTip = config['app']['productionTip'];

axios.defaults.baseURL = "/api";

Vue.use(GlobalComponents);
Vue.use(VueSweetalert2);

store.dispatch('auth/exchangeTokenForUserData', localStorage.getItem('token')).then(() => {

    new Vue({
        el: '#app',
        router,
        config,
        store,
        regeneratorRuntime,
        render: h => h(App)
    });

})
