import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {
        
        products: null,
        error: {}
    },

    actions : {
        
        /**
         * Action to fetch ALL products
         *
         * @param dispatch
         * @param token
         *
         * @returns {Promise<*>}
         */
        async fetchProducts({ commit }, token) {
            
            let response = await axios
                
                .get('/get/products', {
                    
                    headers: {
                        Authorization: "Bearer " + token
                    }
                    
                })
                
                .then(function (response) {
                    
                    commit('saveProducts', response.data)
                    commit(ERROR_MESSAGE, null)
                    
                })
                
                .catch(function (error) {
                    
                    commit("saveProducts", null)
                    
                    if (error.response) {
                        
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        
                        commit(ERROR_MESSAGE, error.response)
                        
                    } else if (error.request) {
                        
                        // The request was made but no response was received
                        console.log(error.request);
                        
                        commit(ERROR_MESSAGE, error.request)
                        
                    } else {
                        
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        
                        commit(ERROR_MESSAGE, error.message)
                    }
                })
        },

    },

    getters : {
        
        getProducts(state) {

            return state.products
        },
        
        error(state) {
            
            return state.error
        }

    },

    mutations : {
        
        saveProducts(state, payload) {

            state.products = payload
        },
        
        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        }

    }
}
