/* eslint-disable */
import axios from "axios"

export default {

    namespaced: true,

    state : {
        endpoints: null
    },

    actions : {

        /**
         * Action to fetch ALL endpoints
         *
         * @param dispatch
         *
         * @returns {Promise<*>}
         */
        async fetchEndpoints({ commit }) {

            try {

                let response = await axios.get('/get/endpoints')

                commit('saveEndpoints', response.data)

            } catch (e) {

                console.log("fetching endpoints failed")

                commit("saveEndpoints", null)
            }
        }

    },

    getters : {

        endpoints(state) {

            return state.endpoints
        }
    },

    mutations : {

        saveEndpoints(state, payload) {

            state.endpoints = payload

        }
    }
}
