import Vue from 'vue'
import Router from 'vue-router'

// (^) is an alias for ./src/views
import Overview from '^/dashboard/Overview';
import WorkCard from '^/sales/_partials/work-card';
import FullUserDetails from '^/user/full-details';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/dashboard',
            name: 'overview',
            component: Overview
        },
        // {
        //     path: '/sales/order/new',
        //     name: 'work-card',
        //     component: WorkCard
        // },
        {
            path: '/sales/order/:id',
            name: 'work-card',
            component: WorkCard
        },
        {
            path: '/user/:id/details',
            name: 'full-user-details',
            component: FullUserDetails
        }
    ]
})
