/* eslint-disable */
import axios from "axios"

export default {

    namespaced: true,

    state : {
        locales: null
    },

    actions : {

        /**
         * Action(s) to signin an existing user
         *
         * @param dispatch
         *
         * @returns {Promise<*>}
         */
        async fetchLocales({ commit }) {

            try {

                let response = await axios.get('/get/locale/admin')

                commit('saveLocales', response.data)

            } catch (e) {

                console.log("fetching locales failed")

                commit("saveLocales", null)
            }
        }

    },

    getters : {

        locales(state) {

            return state.locales
        }
    },

    mutations : {

        saveLocales(state, payload) {
            state.locales = payload
        }
    }
}
