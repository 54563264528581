const mode = process.env.NODE_ENV;

function loadConfigs() {

    let scheme      = {};

    let api         = {};
    let firebase    = {};
    let google_auth = {};
    let google_maps = {};
    let providers   = {};

    switch (mode) {

        case "development":

            api         = require("config/vue/development/api.json");
            firebase    = require("config/vue/development/firebase.json");
            google_auth = require("config/vue/development/googleAuth.json");
            google_maps = require("config/vue/development/googleMaps.json");
            providers   = require("config/vue/development/providers.json");

            scheme = {
                app: {
                    name: "nexusAPI",
                    description: "Very Cool app to manage API ressources in your eco-system",
                    //version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                api: api,
                firebase: firebase,
                gauth: google_auth,
                gmaps: google_maps,
                providers: providers
            };

            return scheme;

        case "production":

            api         = require("config/vue/production/api.json");
            firebase    = require("config/vue/production/firebase.json");
            google_auth = require("config/vue/production/googleAuth.json");
            google_maps = require("config/vue/production/googleMaps.json");
            providers   = require("config/vue/production/providers.json");

            scheme = {
                app: {
                    name: "nexusAPI",
                    description: "Very Cool app to manage API ressources in your eco-system",
                    //version: version,
                    mode: mode,
                    devtools: true,
                    productionTip: false
                },
                api: api,
                firebase: firebase,
                gauth: google_auth,
                gmaps: google_maps,
                providers: providers
            };

            return scheme;

        default:

            return scheme;
    }
}

export default loadConfigs()