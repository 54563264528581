<!-- eslint-disable -->
<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<!-- eslint-disable -->
<script>

    // (~) is an alias for ./src/components

    // importing Vuex maps
    import {mapActions, mapGetters} from "vuex";

    // importing config
    import config from "../../config/vue/configurator";

    let every = {
        oneSecond       : 1000,
        twoSeconds      : 2000,
        treeSeconds     : 3000,
        fourSeconds     : 4000,
        oneMinute       : 60000,
        twoMinutes      : 120000,
        threeMinutes    : 180000,
        fourMinutes     : 240000,
        fiveMinutes     : 300000,
        fifteenMinutes  : 900000,
        oneHour         : 3600000,
        twoHour         : 7200000,
        threeHour       : 10800000,
        fourHour        : 14400000
    };

    export default {
        name: 'app',
        components: {

            // ...

        },
        created() {

            /*
             *
             */
            this.authenticate()

        },
        mounted() {

            // ...

        },
        data() {
            return {

                // ...
                credentials: {
                    email: config['api']['user'],
                    password: config['api']['secret']
                },

            }
        },
        computed: {

            ...mapGetters({

                token: 'auth/authenticated'
            }),

        },
        methods: {

            ...mapActions({

                login: 'auth/login',
                getLocales: 'locales/fetchLocales',
                getOrders: 'order/fetchOrders',
                getProducts: 'product/fetchProducts',

            }),

            authenticate() {

                this.login(this.credentials)

                    .then(() => {

                        console.log("API bot authenticated")

                        //this.populateLocales(this.token) // TODO : SKAL kigges på når vi tager vue ordentligt i brug!
                        this.populateOrders(this.token)
                        this.populateProducts(this.token)

                    })

                    .catch(() => {

                        console.log("API bot (catch) failed")

                    })
            },

            populateLocales(token) {

                this.getLocales(token)

                    .then(() => {

                        console.log("Locales state populated")

                    })

                    .catch(() => {

                        console.log("Fetching locales (catch) failed")

                    })

            },

            populateOrders(token) {

                this.getOrders(token)

                    .then(() => {

                        console.log("Orders state populated")

                    })

                    .catch(() => {

                        console.log("Fetching orders (catch) failed")

                    })

            },

            populateProducts(token) {

                this.getProducts(token)

                    .then(() => {

                        console.log("Products state populated")

                    })

                    .catch(() => {

                        console.log("Fetching products (catch) failed")

                    })

            },

        }
    }

</script>

<!-- eslint-disable -->
<style lang="scss">

    // ...

</style>
