/* eslint-disable */

/**
 * Importing other assets
 */
import { quillEditor } from 'vue-quill-editor';
import draggable from 'vuedraggable';

/**
 * Register global components here and use them as a plugin in main Vue instance
 */
const GlobalComponents = {
    
    install(Vue) {
        
        Vue.component("quill-editor", quillEditor)
        Vue.component("draggable", draggable)

    }
    
};

export default GlobalComponents;
