var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row align-items-center" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-1 text-end" }, [
        _c("div", { staticClass: "dropstart" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "dropdown-menu dropdown-menu-lg-start px-2 py-3",
              attrs: { "aria-labelledby": "dropdownMarketingCard" },
            },
            [
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("li", [
                _c(
                  "button",
                  {
                    staticClass: "dropdown-item border-radius-md text-danger",
                    on: { click: _vm.showAlert },
                  },
                  [
                    _vm._v(
                      "\n                                Fjern\n                            "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("input", {
      staticClass: "form-control text-center text-2xl font-weight-bolder",
      attrs: {
        id: "orderNumber",
        name: "orderNumber",
        type: "text",
        readonly: "",
        "aria-label": "orderNumber",
      },
      domProps: { value: "Arbejdskort sagsnr.: " + _vm.order.id },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-12 col-md-6 col-xl-6 mt-md-0 mt-4" }, [
        _c(
          "div",
          { staticClass: "h-100" },
          [
            _vm._m(4),
            _vm._v(" "),
            _vm.order.customer
              ? [
                  _c(
                    "div",
                    { staticClass: "form-control card-body p-3 mh-205" },
                    [
                      _c("ul", { staticClass: "list-group" }, [
                        _c(
                          "li",
                          {
                            staticClass:
                              "list-group-item border-0 ps-0 pt-0 text-sm",
                          },
                          [
                            _vm.order.customer.middle_names
                              ? [
                                  _c("strong", { staticClass: "text-dark" }, [
                                    _vm._v("Full Name:"),
                                  ]),
                                  _vm._v(
                                    "   " +
                                      _vm._s(
                                        _vm.order.customer.first_name +
                                          " " +
                                          _vm.order.customer.middle_names +
                                          " " +
                                          _vm.order.customer.last_name
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              : [
                                  _c("strong", { staticClass: "text-dark" }, [
                                    _vm._v("Full Name:"),
                                  ]),
                                  _vm._v(
                                    "   " +
                                      _vm._s(
                                        _vm.order.customer.first_name +
                                          " " +
                                          _vm.order.customer.last_name
                                      ) +
                                      "\n                                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "list-group-item border-0 ps-0 text-sm",
                          },
                          [
                            _vm._l(
                              _vm.order.customer.addresses,
                              function (address) {
                                return address.pivot.address_type === "home"
                                  ? [
                                      _c(
                                        "strong",
                                        { staticClass: "text-dark" },
                                        [_vm._v("Address:")]
                                      ),
                                      _vm._v(
                                        "   " +
                                          _vm._s(address.address1) +
                                          "\n                                    "
                                      ),
                                    ]
                                  : _vm._e()
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "list-group-item border-0 ps-0 text-sm",
                          },
                          [
                            _c("strong", { staticClass: "text-dark" }, [
                              _vm._v("Email:"),
                            ]),
                            _vm._v(
                              "   " +
                                _vm._s(_vm.order.customer.email) +
                                "\n                                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "list-group-item border-0 ps-0 text-sm",
                          },
                          [
                            _vm.order.customer.phone.tel_mobile
                              ? [
                                  _c("strong", { staticClass: "text-dark" }, [
                                    _vm._v("Mobile:"),
                                  ]),
                                  _vm._v(
                                    "   " +
                                      _vm._s(
                                        _vm.order.customer.phone.tel_mobile
                                      ) +
                                      "\n                                    "
                                  ),
                                ]
                              : [
                                  _c("strong", { staticClass: "text-dark" }, [
                                    _vm._v("Mobile:"),
                                  ]),
                                  _vm._v(
                                    "   n/a\n                                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm._m(5),
                      ]),
                    ]
                  ),
                ]
              : [_vm._m(6)],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-md-6 col-xl-6 mt-md-0 mt-4" }, [
        _c("div", { staticClass: "h-200" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "form-control card-body p-2 mh-205" }, [
            _c("ul", { staticClass: "list-group padding-sides" }, [
              _c(
                "li",
                {
                  staticClass:
                    "list-group-item border-0 d-flex justify-content-between ps-0 mb-1 border-radius-lg",
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              width: "12px",
                              height: "12px",
                              viewBox: "0 0 40 40",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            },
                          },
                          [
                            _c("title", [_vm._v("spaceship")]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: {
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(-1720.000000, -592.000000)",
                                      fill: "#FFFFFF",
                                      "fill-rule": "nonzero",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform:
                                            "translate(1716.000000, 291.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              transform:
                                                "translate(4.000000, 301.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z",
                                                opacity: "0.598539807",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z",
                                                opacity: "0.598539807",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("h6", { staticClass: "mb-1 text-dark text-sm" }, [
                        _vm._v(
                          "\n                                            Sagsdato:\n                                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-xs" }, [
                        _vm._v("Oprettet d. "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.order.created_at)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass:
                    "list-group-item border-0 d-flex justify-content-between ps-0 mb-1 border-radius-lg",
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "12px",
                              height: "12px",
                              viewBox: "0 0 42 42",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            },
                          },
                          [
                            _c("title", [_vm._v("office")]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: {
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(-1869.000000, -293.000000)",
                                      fill: "#FFFFFF",
                                      "fill-rule": "nonzero",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform:
                                            "translate(1716.000000, 291.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              id: "office",
                                              transform:
                                                "translate(153.000000, 2.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              staticClass: "color-background",
                                              attrs: {
                                                d: "M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z",
                                                opacity: "0.6",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              staticClass: "color-background",
                                              attrs: {
                                                d: "M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("h6", { staticClass: "mb-1 text-dark text-sm" }, [
                          _vm._v(
                            "\n                                            Afdeling:\n                                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.order.department
                          ? [
                              _c("span", { staticClass: "text-xs" }, [
                                _vm._v(_vm._s(_vm.order.department.uid) + ", "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order.department.address.zip +
                                          " " +
                                          _vm.order.department.address.city
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          : [_vm._m(8)],
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(9),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass:
                    "list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg",
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center",
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              width: "12px",
                              height: "12px",
                              viewBox: "0 0 42 42",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            },
                          },
                          [
                            _c("title", [_vm._v("box-3d-50")]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: {
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(-2319.000000, -291.000000)",
                                      fill: "#FFFFFF",
                                      "fill-rule": "nonzero",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          transform:
                                            "translate(1716.000000, 291.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              transform:
                                                "translate(603.000000, 0.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z",
                                                opacity: "0.7",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z",
                                                opacity: "0.7",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(10),
                  ]),
                  _vm._v(" "),
                  _vm._m(11),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-12 col-md-12 col-xl-12 mt-md-0 mt-4" }, [
        _c("div", { staticClass: "h-200" }, [
          _vm._m(12),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body no-padding" },
            [
              _c("quill-editor", {
                ref: "orderNoteInternalTextEditor",
                attrs: { config: _vm.editorOption },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col-12 col-md-12 col-xl-12 mt-md-0 mt-4" }, [
        _c("div", { staticClass: "h-200" }, [
          _vm._m(13),
          _vm._v(" "),
          _c("div", { staticClass: "form-control card-body no-padding" }, [
            _c("div", { staticClass: "table-responsive p-0" }, [
              _c(
                "table",
                { staticClass: "table align-items-center mb-0" },
                [
                  _vm._m(14),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      attrs: { tag: "tbody" },
                      model: {
                        value: _vm.order.products,
                        callback: function ($$v) {
                          _vm.$set(_vm.order, "products", $$v)
                        },
                        expression: "order.products",
                      },
                    },
                    _vm._l(_vm.order.products, function (product) {
                      return _c("tr", [
                        _c("td", [
                          _c("div", { staticClass: "d-flex px-3 py-1" }, [
                            _c("div", [
                              _c("img", {
                                staticClass: "avatar me-3",
                                attrs: {
                                  src: "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/blue-shoe.jpg",
                                  alt: "image",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-content-center",
                              },
                              [
                                _c("h6", { staticClass: "mb-0 text-sm" }, [
                                  _vm._v(
                                    "\n                                                        produkt titel\n                                                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm font-weight-bold text-secondary mb-0",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                                        sku: "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [_vm._v(_vm._s(product.sku))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm text-end font-weight-bold mb-0",
                            },
                            [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(product.pivot.quantity) +
                                  "\n                                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "align-middle text-start text-sm" },
                          [
                            _c(
                              "p",
                              { staticClass: "text-sm font-weight-bold mb-0" },
                              [
                                _vm._v(
                                  "\n                                                stk.\n                                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "align-middle text-center" }, [
                          _vm._v(
                            "\n                                            " +
                              _vm._s(
                                _vm._f("percentage")(
                                  product.pivot.discount_percentage
                                )
                              ) +
                              "\n                                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-end" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm text-end font-weight-bold mb-0",
                            },
                            [
                              _vm._v(
                                "\n                                                " +
                                  _vm._s(
                                    _vm._f("number")(product.sales_price)
                                  ) +
                                  "\n                                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-end" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-sm text-end font-weight-bold mb-0",
                            },
                            [
                              _vm._v(
                                "\n                                                123000\n                                            "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-10 offset-1" }, [
      _c(
        "h6",
        { staticClass: "mb-0 text-center font-weight-bolder ms-3 mb-3" },
        [
          _vm._v(
            "\n                    tune trafikskole, Industrihegnet 9, 4030 Tune, +45 70 60 30 15, info@tune-trafikskole.dk\n                "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "text-secondary btn no-shadow",
        attrs: {
          id: "dropdownMarketingCard",
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fas fa-ellipsis-v" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("a", { staticClass: "dropdown-item border-radius-md" }, [
        _vm._v(
          "\n                                ...\n                            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("hr", { staticClass: "dropdown-divider" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header no-padding" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 d-flex align-items-center" }, [
          _c("h6", { staticClass: "mb-0 pb-2" }, [
            _vm._v(
              "\n                                    Kunde Information\n                                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 text-end" }, [
          _c("a", { attrs: { href: "javascript:" } }, [
            _c("i", {
              staticClass: "fas fa-user-edit text-secondary text-sm",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "top",
                title: "Edit Profile",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "list-group-item border-0 ps-0 pb-0" }, [
      _c("strong", { staticClass: "text-dark text-sm" }, [_vm._v("Social:")]),
      _vm._v("  \n                                    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "form-control card-body d-flex flex-column justify-content-center text-center mh-205",
      },
      [
        _c("a", { attrs: { href: "javascript:" } }, [
          _c("i", {
            staticClass: "fa fa-plus text-secondary text-sm mb-1",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("h6", { staticClass: "text-secondary" }, [_vm._v(" Tilføj ")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header no-padding" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 d-flex align-items-center" }, [
          _c("h6", { staticClass: "mb-0 pb-2" }, [
            _vm._v(
              "\n                                    Information om forløb\n                                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 text-end" }, [
          _c("a", { attrs: { href: "javascript:;" } }, [
            _c("i", {
              staticClass: "fas fa-user-edit text-secondary text-sm",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "top",
                title: "Edit Profile",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "text-xs" }, [
      _vm._v("Afdeling "),
      _c("span", { staticClass: "font-weight-bold" }, [_vm._v("EJ SAT ENDNU")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c(
        "button",
        {
          staticClass:
            "btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto",
        },
        [
          _c("i", {
            staticClass: "ni ni-bold-right",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c("h6", { staticClass: "mb-1 text-dark text-sm" }, [
        _vm._v(
          "\n                                            Køreprøvebooking:\n                                        "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "text-xs" }, [
        _vm._v("Forløbsnr. "),
        _c("span", { staticClass: "font-weight-bold" }, [_vm._v("Ukendt")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c(
        "button",
        {
          staticClass:
            "btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto",
        },
        [
          _c("i", {
            staticClass: "ni ni-bold-right",
            attrs: { "aria-hidden": "true" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header no-padding" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 d-flex align-items-center" }, [
          _c("h6", { staticClass: "mb-0 pb-2" }, [
            _vm._v(
              "\n                                    Interne Bemærkninger\n                                "
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header no-padding" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-8 d-flex align-items-center" }, [
          _c("h6", { staticClass: "mb-0 pb-2" }, [
            _vm._v(
              "\n                                    Ordrerlinjer\n                                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4 text-end" }, [
          _c("a", { attrs: { href: "javascript:" } }, [
            _c("i", {
              staticClass: "fas fa-plus text-secondary text-sm",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "top",
                title: "Edit Profile",
              },
            }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          {
            staticClass:
              "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          },
          [
            _vm._v(
              "\n                                            Produkt\n                                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1",
          },
          [
            _vm._v(
              "\n                                            Antal\n                                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 p-1",
          },
          [
            _vm._v(
              "\n                                            Enhed\n                                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          },
          [
            _vm._v(
              "\n                                            Rabat\n                                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          },
          [
            _vm._v(
              "\n                                            Stk. pris\n                                        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass:
              "text-end text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          },
          [
            _vm._v(
              "\n                                            Pris\n                                        "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }