/* eslint-disable */
import axios from "axios"

const ERROR_MESSAGE = 'ERROR_MESSAGE'

export default {

    namespaced: true,

    state : {
        token: null,
        user: null,
        error: {}
    },

    actions : {

        /**
         * Action(s) to sign-in an existing user
         *
         * @param dispatch
         * @param credentials
         *
         * @returns {Promise<*>}
         */
        async login({ dispatch }, credentials) {

            let response = await axios.post('/auth/fetch-token', credentials)

            // try {
            //
            //
            //
            // } catch (error) {

                // this.$toastr.Add({
                //     name: "AuthToastr",
                //     title: error.response.status,
                //     msg: error.response.data,
                //     clickClose: true,
                //     timeout: 5000,
                //     progressBar: true,
                //     position: "toast-top-right",
                //     type: "error",
                //     //preventDuplicates: true
                // });

            // }

            return dispatch('exchangeTokenForUserData', response.data.token)
        },

        async exchangeTokenForUserData({ commit, dispatch, state }, token) {

            if (token) {

                commit('saveAuthToken', token)
            }

            if (!state.token) {

                return
            }

            let response = await axios

                .get('/get/user/me')

                .then(function (response) {

                    commit('saveAuthUser', response.data)
                    commit(ERROR_MESSAGE, null)

                })

                .catch(function (error) {

                    commit("saveAuthToken", null)
                    commit("saveAuthUser", null)

                    if (error.response) {

                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        commit(ERROR_MESSAGE, error.response)

                    } else if (error.request) {

                        // The request was made but no response was received
                        console.log(error.request);

                        commit(ERROR_MESSAGE, error.request)

                    } else {

                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);

                        commit(ERROR_MESSAGE, error.message)
                    }
                })

        },

        logout({ commit }) {

            console.log("logout finished")

            commit("saveAuthToken", null)
            commit("saveAuthUser", null)
        }
    },

    getters : {
        
        authenticated(state) {

            return state.token
        },
        
        user(state) {
            
            return state.user
        },

        error(state) {

            return state.error
        }
    },

    mutations : {

        [ERROR_MESSAGE]: function (state, payload) {
            state.error = payload
        },

        saveAuthToken(state, payload) {
            state.token = payload
        },

        saveAuthUser(state, payload) {
            state.user = payload
        }
    }
}
