import axios from "axios"

export default {

    namespaced: true,

    state : {

        users: null
    },

    actions : {

        /**
         * Action to fetch ALL users
         *
         * @param dispatch
         *
         * @returns {Promise<*>}
         */
        async fetchUsers({ commit }) {

            try {

                let response = await axios.get('/get/users')

                commit('saveUsers', response.data)

            } catch (e) {

                commit("saveUsers", null)
            }
        },

        /**
         * Action to DELETE a single user
         *
         * @param dispatch
         * @param id
         *
         * @returns {Promise<*>}
         */
        async deleteUser({ commit }, id) {

            try {

                let response = await axios.delete('/delete/user/' + id)

                console.log(response.request.url)

                commit('dumpUser', id)

            } catch (e) {

                // ...do something
            }
        },

    },

    getters : {

        users(state) {

            return state.users
        },

    },

    mutations : {

        saveUsers(state, payload) {

            state.users = payload
        },

        dumpUser(state, payload) {

            //state.users = payload
        },

    }
}
